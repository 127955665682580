import React, { FC } from 'react';
import DepartsSoonList from '../../components/PublicTransit/DepartsSoonList';
import { useLocation } from '../../hooks/useLocation';

const GPublicTransitQuickInfo: FC<{ vertical?: boolean }> = ({ vertical }) => {
  const { data: location } = useLocation();
  if (!location) {
    return null;
  }

  return <DepartsSoonList horizontal={!vertical} />;
};

export default GPublicTransitQuickInfo;
